<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
export default {
  name: 'App',
  mounted() {
    //admin route check
    if(location.href.split('/admin').length > 1) {
      localStorage.setItem('wantToLogin', true)
      location.href = '/'
    }
    else {
      setTimeout(() => {
        localStorage.setItem('wantToLogin', false)
      },1000)
    }
  }
}
</script>