<template>
  <div class="marquee">
<!--      <MarqueeText :duration="55" :repeat="20" :reverse="true" >
      <span class="banner-text">
        <img src="../assets/img/slide-011.webp" draggable="false" />
      </span>
    </MarqueeText> -->
    <MarqueeText :duration="55" :repeat="20" :reverse="true">
      <span class="banner-text">
        <img src="../assets/img/slide-022.webp" draggable="false" />
      </span>
    </MarqueeText>

    <div id="origins"></div>
  </div>
</template>

<script>
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "Marquee",

  components: {
    MarqueeText,
  },

  data() {
    return {
      marqueeOptions: {
        direction: "left",
        showProgress: "false",
      },
    };
  },
};
</script>

<style>
  .marquee{
    position: relative;
  }

  #origins{
    position: absolute;
    bottom: 3rem;
    left: 0;
  }

</style>

