import en from "./locales/en.json";
import es from "./locales/es.json";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale:
    localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : "es",
  messages: {
    en: en,
    es: es,
  },
});