import Vue from 'vue'
import App from './App.vue'
import router from './router'


import './themes/style.scss'
import './themes/resp.scss'
import './themes/gallery-resp.scss'
import './fonts/stylesheet.css'

import i18n from "./i18n";
import 'animate.css'
import "animate.css";

import MarqueeText from 'vue-marquee-text-component';
Vue.component('marquee-text', MarqueeText);


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);


import VModal from 'vue-js-modal';
Vue.use(VModal)

import axios from "axios";
axios.defaults.baseURL = "https://iviv3auwr9.execute-api.us-east-1.amazonaws.com/dev";


const lang = localStorage.getItem("lang") || "en";
document.documentElement.lang = lang;

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
   mounted() {
  },
}).$mount('#app')
