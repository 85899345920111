<template>
  <div class="familias" v-if="loaded">
   
      <p class="worlds-name hideIpad">Humania</p>
      <p class="worlds-name hideIpad">Distobia</p>
      <p class="worlds-name hideIpad">Amphibia</p>
      <p class="worlds-name hideIpad">Posthumania</p>
 
    <!-- SLIDER PROVA -->

    <carousel :per-page="1" :mouse-drag="true" :navigationEnabled="true">
      <slide data-index="0">
        <div class="carousel-cell" id="humania">
          <img
            class="showIpad md-worlds"
            src="../assets/familias/world_humania.png"
            draggable="false"
          />

         <!--  <div class="slider-text">
            <h1>HUMANIA</h1>
            <p>
              {{ $t("slider.humania-txt1") }}
            </p>
            <p>
              <span>
                <img src="../assets/icons/sun.svg" draggable="false" />
              </span>
              {{ $t("slider.humania-txt2") }}
            </p>
          </div> -->

          <div class="slider-images">
            <h1 class="slider-titles-mob">HUMANIA</h1>
            <img
              src="../assets/familias/Humania.gif"
              draggable="false"
              class="hideIpad"
            />
            <img
              src="../assets/familias/Humania01.gif"
              draggable="false"
              class="hideDesktop showIpad"
            />
          </div>
        </div>
      </slide>


      <slide data-index="1">
        <div class="carousel-cell" id="distobia">
          
          <img
            class="showIpad md-worlds"
            src="../assets/familias/world_distobia.png"
            draggable="false"
          />
         <!--  <div class="slider-text">
            <h1>DISTOBIA</h1>
            <p>
              {{ $t("slider.distobia-txt1") }}
            </p>
            <p>
              <span>
                <img src="../assets/icons/sun.svg" draggable="false" />
              </span>
              {{ $t("slider.distobia-txt2") }}
            </p>
          </div> -->

          <div class="slider-images">
            <h1 class="slider-titles-mob">DISTOBIA</h1>
            <img
              src="../assets/familias/Distobia.gif"
              draggable="false"
              class="hideIpad"
            />
            <img
              src="../assets/familias/Distobia01.gif"
              draggable="false"
              class="hideDesktop showIpad"
            />
          </div>
        </div>
      </slide>
      <slide data-index="2">
        <div class="carousel-cell" id="amphibia">
          <h1 class="slider-titles-mob">AMPHIBIA</h1>
          <img
            class="showIpad md-worlds"
            src="../assets/familias/world_amphibia.png"
            draggable="false"
          />
          <!-- <div class="slider-text">
            <h1>AMPHIBIA</h1>
            <p>
              {{ $t("slider.amphibia-txt1") }}
            </p>
            <p>
              <span>
                <img src="../assets/icons/sun.svg" draggable="false" />
              </span>
              {{ $t("slider.amphibia-txt2") }}
            </p>
          </div> -->

          <div class="slider-images">
            <h1 class="slider-titles-mob">AMPHIBIA</h1>
            <img
              src="../assets/familias/Amphibia.gif"
              draggable="false"
              class="hideIpad"
            />
            <img
              src="../assets/familias/Amphibia01.gif"
              draggable="false"
              class="hideDesktop showIpad"
            />
          </div>
        </div>
      </slide>
      <slide data-index="3">
        <div class="carousel-cell" id="posthumania">
          <img
            class="showIpad md-worlds"
            src="../assets/familias/world_posthumania.png"
            draggable="false"
          />
          <!-- <div class="slider-text">
            <h1>POSTHUMANIA</h1>
            <p>
              {{ $t("slider.posthumania-txt1") }}
            </p>
            <p>
              <span>
                <img src="../assets/icons/sun.svg" draggable="false" />
              </span>
              {{ $t("slider.posthumania-txt2") }}
            </p>
          </div> -->

          <div class="slider-images">
            <h1 class="slider-titles-mob">POSTHUMANIA</h1>
            <img
              src="../assets/familias/Posthumania.gif"
              draggable="false"
              class="hideIpad"
            />
            <img
              src="../assets/familias/PostHumania01.gif"
              draggable="false"
              class="hideDesktop showIpad"
            />
          </div>
        </div>
      </slide>
      
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Slider",

  components: {
    Carousel,
    Slide,
  },

  data() {
    return {
      loaded: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
};
</script>

<style>

  .slider-titles-mob{
    position: absolute;
    left: 2rem;
    top: 10.5rem;
    margin-bottom: 0;
    display: none;
  }
</style>