<template>
  <div class="home">
    <Header />
    <MobileMenu />
    <Intro />
    <Marquee />
    <Origenes />
    <Slider />
    <Causas />
    <Utilidades />
    <Roadmap />
    <Team />
    <!-- Faqs -->
    <div class="faqs-section">
      <div id="faqs"></div>
      <div class="faqs-boy-bg hideIpad"></div>
      <div class="container-fluid">
        <div class="faqs-wrapper" v-if="website.faqs !== undefined && website.faqs.length >= 0">
          <h1>Faqs</h1>
          <div class="faqs" v-for="(faq, index) in website.faqs" :key="faq.index">
            <div class="faqs-q">
              <div class="faqs-question">
                <h4 v-if="!isAdmin" v-html="$i18n.locale === 'es' ? faq.question_en : faq.question_en"></h4>
                <h4 v-if="isAdmin" style="width: 90%;"><input type="text" placeholder="Question text"
                    v-model="website.faqs[index].question_en"></h4>
                <div id="faqs-btn-container" @click="website.faqs[index].opened = !website.faqs[index].opened"
                  :class="website.faqs[index].opened ? 'open' : ''">
                  <div class="faqs-button"></div>
                  <div class="faqs-button"></div>
                  <div class="faqs-button"></div>
                </div>
              </div>

              <div v-show="faq.opened">
                <div v-if="!isAdmin" v-html="$i18n.locale === 'es' ? faq.answer_es : faq.answer_en"></div>
                <div v-if="isAdmin">
                  <strong>Answer (EN)</strong><br>
                  <textarea type="text" placeholder="answer text"
                    v-model="website.faqs[index].answer_en"></textarea><br>
                  <strong class="mt-2">Answer (ES)</strong><br>
                  <textarea type="text" placeholder="answer text" v-model="website.faqs[index].answer_es"></textarea>
                  <button style="border:1px solid var(--dpurple); background: none; padding: .5rem;color: var(--dpurple);" v-if="isAdmin && mobile" @click="(website.faqs[index].question_en) ? modals.faq = index : deleteFaq(index)"><img src="../assets/icons/trash.svg" alt="Delete Faq" style="width:18px;transform: translateY(-3px);margin-right: .2rem;">Delete Faq</button>
                </div>
              </div>
            </div>
            <img src="../assets/icons/trash.svg" alt="Delete Faq" v-if="isAdmin && !mobile"
              @click="(website.faqs[index].question_en) ? modals.faq = index : deleteFaq(index)">
          </div>
          <div v-if="isAdmin">
            <div class="faqs-q">
              <div class="faqs-question" style="justify-content: center;">
                <div id="faqs-btn-container">
                  <span style="scale:3;" @click="addFaq()">+</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of Faqs-->
    <!-- <NewFuture />
    <Video />
    <MintingSection />
    <Rarities />
    <Attributes />
    <Village />
    
    <Partners />
     -->
    <Footer />
    <!-- <Copy /> -->
    <!-- <Mint /> -->
    <!-- Editing mode section-->
    <EditingModal v-if="website.faqs && website.faqs[modals.faq]"
      :title='`Are you sure you want to delete <br><p>"<b><i>${website.faqs[modals.faq].question_en.length > 40 ? website.faqs[modals.faq].question_en.slice(0, 40) + "..." : website.faqs[modals.faq].question_en}</i></b>"?</p> `'
      @confirm="deleteFaq(modals.faq)" @cancel="modals.faq = -1" />
    <EditingModal v-if="modals.logout"
      :title='`Are you sure you want to log out? Any unsaved change <b>will be lost</b>`' @confirm="logout()"
      @cancel="modals.logout = false" />

    <!--Login-->
    <div class="editing_modal_wrapper" v-if="modals.login" @keyup.enter="login()">
      <div class="editing_modal">
        <img src="../assets/icons/close.svg" style="width:25px" alt="Cancel" @click="modals.login = false">
        <h4 class="mt-2 pt-2">Login</h4>
        <div class="d-flex flex-column">
          <input type="text" placeholder="Username" class="adminInput" v-model="forms.login.username">
          <input type="password" placeholder="Password" class="adminInput" v-model="forms.login.password">
          <p class="text-danger" v-html="forms.login.error"></p>
        </div>
        <div class="d-flex mx-auto mt-4">
          <button class="confirmBtn mx-auto" v-if="!forms.login.loading" @click="login()">Login</button>
          <button class="confirmBtn mx-auto" v-if="forms.login.loading"><img src="../assets/icons/loader.svg"
              class="spinning" alt="Loading..."></button>
        </div>
      </div>
    </div>

    <!--Change password-->
    <div class="editing_modal_wrapper" v-if="modals.changePass" @keyup.enter="changePass()">
      <div class="editing_modal">
        <img src="../assets/icons/close.svg" style="width:25px" alt="Cancel" @click="modals.changePass = false">
        <h4 class="mt-2 pt-2">Change Password</h4>
        <div class="d-flex flex-column">
          <input type="password" placeholder="Old Password" class="adminInput" v-model="forms.changePass.old_password">
          <input type="password" placeholder="Password" class="adminInput" v-model="forms.changePass.password">
          <input type="password" placeholder="Password Confirmation" class="adminInput"
            v-model="forms.changePass.password_confirmation">
          <p class="text-danger" v-html="forms.changePass.error"></p>
        </div>
        <div class="d-flex mx-auto mt-4">
          <button class="confirmBtn mx-auto" v-if="!forms.changePass.loading" @click="changePass()">Change</button>
          <button class="confirmBtn mx-auto" v-if="forms.changePass.loading"><img src="../assets/icons/loader.svg"
              class="spinning" alt="Loading..."></button>
        </div>
      </div>
    </div>

    <!-- Saved status-->
    <div class="saved_status" v-if="modals.savedStatus >= 0"
      :style="(modals.savedStatus === 0) ? 'background: rgb(25, 192, 114);' : 'background: rgb(192 71 25);'">
      <div class="d-flex" v-if="modals.savedStatus === 0">
        <img src="../assets/icons/check.svg" style="width: 20px;margin-right: .5rem;">
        <p>Changes saved successfully!</p>
      </div>
      <div class="d-flex" v-if="modals.savedStatus === 1">
        <img src="../assets/icons/plus.svg" style="width: 20px;margin-right: .5rem;transform: rotateZ(45deg);">
        <p>Some error occurred during changes saving</p>
      </div>
    </div>

    <div class="admin_menu" v-if="isAdmin">
      <div class="adminBtn" @click="saveWebsiteContent()" v-if="!websiteLoading">Save</div>
      <div class="adminBtn" v-if="websiteLoading">
        <img src="../assets/icons/loader.svg" class="spinning" alt="Loading...">
      </div>
      <div class="adminBtn" @click="modals.logout = true">
        <img src="../assets/icons/close.svg" style="width: 20px;" alt="Logout">
      </div>
      <div class="adminBtn" @click="modals.changePass = true">Change Password</div>
    </div>
    <!--end of Editing mode section-->

  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import Intro from "@/components/Intro.vue";
import Marquee from "@/components/Marquee.vue";
import Origenes from "@/components/Origenes.vue";
import Slider from "@/components/Slider.vue";
import Causas from "@/components/Causas.vue";
import Utilidades from "@/components/Utilidades.vue";
import Roadmap from "@/components/Roadmap.vue";
import Team from "@/components/Team.vue";
import EditingModal from '@/components/EditingModal.vue';
/* import NewFuture from "@/components/NewFuture.vue";
import MintingSection from "@/components/MintingSection.vue";
import Attributes from "@/components/Attributes.vue";
import Village from "../components/Village.vue";
 */
import Footer from "@/components/Footer.vue";
/* import Copy from "@/components/Copy.vue"; */
/*import Video from "@/components/Video.vue";
import Mint from "@/components/Mint.vue";
import Partners from "../components/Partners.vue";
import Rarities from "../components/Rarities.vue";
 */
import axios from 'axios'
export default {
  name: "Home",
  components: {
    Header,
    MobileMenu,
    Intro,
    Marquee,
    Origenes,
    Slider,
    Causas,
    Utilidades,
    Roadmap,
    Team,
    EditingModal,
    /* NewFuture,
    MintingSection,
    Attributes,
    Village,
     */
    Footer,
    /*  Copy, */
    /*Video,
    Mint,
    Partners,
    Rarities */
  },
  data() {
    return {
      isAdmin: false,
      website: {},
      modals: {
        faq: -1,
        logout: false,
        changePass: false,
        login: JSON.parse(localStorage.getItem('wantToLogin')),
        savedStatus: -1
      },
      forms: {
        login: {
          username: '',
          password: '',
          loading: false,
          error: ''
        },
        changePass: {
          password: '',
          password_confirmation: '',
          old_password: '',
          loading: false,
          error: ''
        }
      },
      websiteLoading: false,
      mobile: window.matchMedia('(max-width: 767px)').matches
    };
  },
  methods: {
    async adminCheck() {
      try {
        const res = await axios.get(process.env.VUE_APP_API_URL + '/admin')
        console.log(res)
        this.isAdmin = res.data
      }
      catch (e) {
        alert(e)
      }
    },
    async getWebsiteContent() {
      try {
        const res = await axios.get(process.env.VUE_APP_API_URL)
        this.website = res.data
      }
      catch (e) {
        alert(e)
      }
    },
    addFaq() {
      this.website.faqs.push({ question_en: "", answer_es: "", answer_en: "", opened: true })
    },
    deleteFaq(index) {
      if (this.website.faqs[index]) {
        let temp = this.website.faqs
        temp[index] = null
        this.website.faqs = []
        temp.forEach(el => {
          if (el) {
            this.website.faqs.push(el)
          }
        })
        this.modals.faq = -1
      }
    },
    async saveWebsiteContent() {
      const app = this
      if (!app.websiteLoading) {
        app.websiteLoading = true

        //reset all faqs opened status to false
        app.website.faqs.forEach(el => {
          el.opened = false
        })

        try {
          await axios.post(process.env.VUE_APP_API_URL + '/', {
            website: app.website
          })
          app.modals.savedStatus = 0
          app.websiteLoading = false
          let tmo = setTimeout(() => {
            app.modals.savedStatus = -1
            clearTimeout(tmo)
          }, 3100)
        }
        catch (e) {
          app.modals.savedStatus = 1
          app.websiteLoading = false
        }
      }
    },
    async logout() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/logout')
        location.reload()
      }
      catch (e) {
        alert(e)
      }
    },
    async login() {
      const app = this
      if (!app.forms.login.loading) {
        app.forms.login.loading = true
        try {
          await axios.post(process.env.VUE_APP_API_URL + '/login', { username: app.forms.login.username, password: app.forms.login.password })
          app.forms.login.loading = false
          app.isAdmin = true
          app.modals.login = false
        }
        catch (e) {
          app.forms.login.error = e?.response?.data?.error
          app.forms.login.loading = false
        }
      }
    },
    async changePass() {
      const app = this
      if (!app.forms.changePass.loading) {
        app.forms.changePass.loading = true
        try {
          await axios.post(process.env.VUE_APP_API_URL + '/change-password', { password: app.forms.changePass.password, password_confirmation: app.forms.changePass.password_confirmation, old_password: app.forms.changePass.old_password })
          app.forms.changePass.loading = false
          app.modals.changePass = false
        }
        catch (e) {
          app.forms.changePass.error = e?.response?.data?.error
          app.forms.changePass.loading = false
        }
      }
    }
  },
  async mounted() {
    axios.defaults.withCredentials = true
    await this.adminCheck()
    await this.getWebsiteContent()
  }
};
</script>
