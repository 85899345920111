<template>
  <div class="causas" id="causes">
    <div class="container-fluid">
      <div class="causas-txt pad-y">
        <!-- <h1>{{ $t("causas.title") }}</h1> -->
        <h1>CAUSES</h1>
        <!-- <div class="sunline-txt">
          <svg
            class="rotate-center hideIpad"
            width="67"
            height="68"
            viewBox="0 0 67 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M54.9448 33.9498L66.7888 28.1394C66.7888 28.1394 54.8512 26.8578 53.6704 26.7318L62.8144 17.2206C62.8144 17.2206 51.1576 20.1006 50.0056 20.3814L55.3444 8.31779C55.3444 8.31779 45.3724 15.0102 44.3896 15.669L45.2788 2.50739C45.2788 2.50739 38.1976 12.2058 37.4992 13.1634L33.8344 0.487793C33.8344 0.487793 30.4972 12.0222 30.1696 13.1598L22.39 2.50379C22.39 2.50379 23.2 14.4846 23.2792 15.6654L12.3244 8.31419C12.3244 8.31419 17.1844 19.2942 17.6632 20.3778L4.85441 17.217C4.85441 17.217 13.1776 25.875 13.9984 26.7282L0.880005 28.1394C0.880005 28.1394 11.662 33.4278 12.724 33.9498L0.880005 39.7602C0.880005 39.7602 12.8176 41.0418 13.9984 41.1678L4.85441 50.679C4.85441 50.679 16.5112 47.799 17.6632 47.5182L12.3244 59.5818C12.3244 59.5818 22.2964 52.8894 23.2792 52.2306L22.39 65.3922C22.39 65.3922 29.4712 55.6938 30.1696 54.7362L33.8344 67.4082C33.8344 67.4082 37.1716 55.8738 37.4992 54.7362L45.2788 65.3922C45.2788 65.3922 44.4688 53.4114 44.3896 52.2306L55.3444 59.5818C55.3444 59.5818 50.4844 48.6018 50.0056 47.5182L62.8144 50.679C62.8144 50.679 54.4912 42.021 53.6704 41.1678L66.7888 39.7602C66.7888 39.7602 56.0068 34.4718 54.9448 33.9498Z"
              fill="#38263F"
            />
          </svg>
          <div class="p-sun-flex">
            <div class="p-sun-d showIpad">
              <div class="d-line"></div>
              <svg
                width="67"
                height="68"
                viewBox="0 0 67 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M54.9448 33.9498L66.7888 28.1394C66.7888 28.1394 54.8512 26.8578 53.6704 26.7318L62.8144 17.2206C62.8144 17.2206 51.1576 20.1006 50.0056 20.3814L55.3444 8.31779C55.3444 8.31779 45.3724 15.0102 44.3896 15.669L45.2788 2.50739C45.2788 2.50739 38.1976 12.2058 37.4992 13.1634L33.8344 0.487793C33.8344 0.487793 30.4972 12.0222 30.1696 13.1598L22.39 2.50379C22.39 2.50379 23.2 14.4846 23.2792 15.6654L12.3244 8.31419C12.3244 8.31419 17.1844 19.2942 17.6632 20.3778L4.85441 17.217C4.85441 17.217 13.1776 25.875 13.9984 26.7282L0.880005 28.1394C0.880005 28.1394 11.662 33.4278 12.724 33.9498L0.880005 39.7602C0.880005 39.7602 12.8176 41.0418 13.9984 41.1678L4.85441 50.679C4.85441 50.679 16.5112 47.799 17.6632 47.5182L12.3244 59.5818C12.3244 59.5818 22.2964 52.8894 23.2792 52.2306L22.39 65.3922C22.39 65.3922 29.4712 55.6938 30.1696 54.7362L33.8344 67.4082C33.8344 67.4082 37.1716 55.8738 37.4992 54.7362L45.2788 65.3922C45.2788 65.3922 44.4688 53.4114 44.3896 52.2306L55.3444 59.5818C55.3444 59.5818 50.4844 48.6018 50.0056 47.5182L62.8144 50.679C62.8144 50.679 54.4912 42.021 53.6704 41.1678L66.7888 39.7602C66.7888 39.7602 56.0068 34.4718 54.9448 33.9498Z"
                  fill="#38263f"
                />
              </svg>
            </div>
            <h2 v-html="$t('causas.txt1')"></h2>
          </div>

          <svg
            class="rotate-center hideIpad"
            width="67"
            height="68"
            viewBox="0 0 67 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M54.9448 33.9498L66.7888 28.1394C66.7888 28.1394 54.8512 26.8578 53.6704 26.7318L62.8144 17.2206C62.8144 17.2206 51.1576 20.1006 50.0056 20.3814L55.3444 8.31779C55.3444 8.31779 45.3724 15.0102 44.3896 15.669L45.2788 2.50739C45.2788 2.50739 38.1976 12.2058 37.4992 13.1634L33.8344 0.487793C33.8344 0.487793 30.4972 12.0222 30.1696 13.1598L22.39 2.50379C22.39 2.50379 23.2 14.4846 23.2792 15.6654L12.3244 8.31419C12.3244 8.31419 17.1844 19.2942 17.6632 20.3778L4.85441 17.217C4.85441 17.217 13.1776 25.875 13.9984 26.7282L0.880005 28.1394C0.880005 28.1394 11.662 33.4278 12.724 33.9498L0.880005 39.7602C0.880005 39.7602 12.8176 41.0418 13.9984 41.1678L4.85441 50.679C4.85441 50.679 16.5112 47.799 17.6632 47.5182L12.3244 59.5818C12.3244 59.5818 22.2964 52.8894 23.2792 52.2306L22.39 65.3922C22.39 65.3922 29.4712 55.6938 30.1696 54.7362L33.8344 67.4082C33.8344 67.4082 37.1716 55.8738 37.4992 54.7362L45.2788 65.3922C45.2788 65.3922 44.4688 53.4114 44.3896 52.2306L55.3444 59.5818C55.3444 59.5818 50.4844 48.6018 50.0056 47.5182L62.8144 50.679C62.8144 50.679 54.4912 42.021 53.6704 41.1678L66.7888 39.7602C66.7888 39.7602 56.0068 34.4718 54.9448 33.9498Z"
              fill="#38263F"
            />
          </svg>
        </div> -->
        <div class="row">
          <div>
            <!-- <p>
              {{ $t("causas.txt2") }}            
            </p> -->
            <div class="causes-sun">
              <svg
                class="showIpad"
                width="67"
                height="68"
                viewBox="0 0 67 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M54.9448 33.9498L66.7888 28.1394C66.7888 28.1394 54.8512 26.8578 53.6704 26.7318L62.8144 17.2206C62.8144 17.2206 51.1576 20.1006 50.0056 20.3814L55.3444 8.31779C55.3444 8.31779 45.3724 15.0102 44.3896 15.669L45.2788 2.50739C45.2788 2.50739 38.1976 12.2058 37.4992 13.1634L33.8344 0.487793C33.8344 0.487793 30.4972 12.0222 30.1696 13.1598L22.39 2.50379C22.39 2.50379 23.2 14.4846 23.2792 15.6654L12.3244 8.31419C12.3244 8.31419 17.1844 19.2942 17.6632 20.3778L4.85441 17.217C4.85441 17.217 13.1776 25.875 13.9984 26.7282L0.880005 28.1394C0.880005 28.1394 11.662 33.4278 12.724 33.9498L0.880005 39.7602C0.880005 39.7602 12.8176 41.0418 13.9984 41.1678L4.85441 50.679C4.85441 50.679 16.5112 47.799 17.6632 47.5182L12.3244 59.5818C12.3244 59.5818 22.2964 52.8894 23.2792 52.2306L22.39 65.3922C22.39 65.3922 29.4712 55.6938 30.1696 54.7362L33.8344 67.4082C33.8344 67.4082 37.1716 55.8738 37.4992 54.7362L45.2788 65.3922C45.2788 65.3922 44.4688 53.4114 44.3896 52.2306L55.3444 59.5818C55.3444 59.5818 50.4844 48.6018 50.0056 47.5182L62.8144 50.679C62.8144 50.679 54.4912 42.021 53.6704 41.1678L66.7888 39.7602C66.7888 39.7602 56.0068 34.4718 54.9448 33.9498Z"
                  fill="#38263f"
                />
              </svg>
              <p id="causesNew">
                Misphits mission is to reverse our planets decline. <br />
                They join a cause in which their experience and sensitivity can
                contribute the most.
              </p>
            </div>
            <!-- <button id="causesNewBtn">
              <a href="#faqs">IRL IMPACTS</a>
            </button> -->
          </div>
        </div>
      </div>

      <div class="causas-hands">
        <div class="row">
          <div class="col">
            <div class="ekuo hideDesktop">
              <img
                src="../assets/img/Causa_Ekuo_flipped.png"
                draggable="false"
                data-aos="slide-up"
                data-aos-delay="800"
                data-aos-duration="5200"
                data-aos-once="false"
                data-aos-anchor=".anchor-mobile-ekuo"
              />
            </div>

            <div class="bukle hideDesktop">
              <img
                src="../assets/img/Causa_Bukle.png"
                draggable="false"
                data-aos="slide-up"
                data-aos-delay="800"
                data-aos-duration="5200"
                data-aos-once="false"
                data-aos-anchor=".anchor-mobile-bukle"
              />
            </div>

            <div class="krono hideDesktop">
              <img
                src="../assets/img/Causa_Krono.png"
                draggable="false"
                data-aos="slide-up"
                data-aos-delay="800"
                data-aos-duration="5200"
                data-aos-once="false"
                data-aos-anchor=".anchor-mobile-krono"
              />
            </div>

            <div class="causas-name aos-trigger">
              <div>
                <img src="../assets/icons/sun.svg" draggable="false" />
                <p class="anchor-mobile-ekuo">Ekuo</p>
              </div>

              <!-- <p>{{ $t("causas.ekuo") }}</p> -->
              <p>
                Social and economic justice, identity politics and human rights.
              </p>
            </div>
          </div>
          <div class="col">
            <div class="causas-name">
              <div>
                <img src="../assets/icons/sun.svg" draggable="false" />
                <p class="anchor-mobile-bukle">Bukle</p>
              </div>
              <!-- <p>
                {{ $t("causas.bukle") }}
              </p> -->
              <p>
                Climate emergency, energy crisis, biodiversity, pollution and
                resource management.
              </p>
            </div>
          </div>

          <div class="col">
            <div class="causas-name">
              <div>
                <img src="../assets/icons/sun.svg" draggable="false" />
                <p class="anchor-mobile-krono">Krono</p>
              </div>

              <!-- <p>{{ $t("causas.krono") }}</p> -->
              <p>Health, rest and lifestyles.</p>
            </div>
          </div>
        </div>

        <img
          src="../assets/img/Causa_Ekuo.png"
          draggable="false"
          class="ekuo hideIpad"
          data-aos="fade-up"
          data-aos-delay="10"
          data-aos-duration="500"
          data-aos-anchor=".anchor"
        />

        <img
          src="../assets/img/Causa_Bukle.png"
          draggable="false"
          class="bukle hideIpad"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="500"
          data-aos-anchor=".anchor"
        />

        <img
          src="../assets/img/Causa_Krono.png"
          draggable="false"
          class="krono hideIpad"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-duration="500"
          data-aos-anchor=".anchor"
        />
        <!-- <button class="anchor anchor-krono">
          <a href="#faqs">IRL IMPACTS</a>
        </button> -->
      </div>
    </div>
    <div id="utilities"></div>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "Causas",

  mounted() {
    setTimeout(function () {
      AOS.init({ once: false });
    }, 1000);
  },
};
</script>

<style>
#utilities {
  position: absolute;
  bottom: 4rem;
  left: 0;
}

#causes {
  position: relative;
}
</style>