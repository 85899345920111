<template>
  <div class="team" id="team">
    <div class="container-fluid">
      <div class="team-half1">
        <h1>{{ $t("menu.team") }}</h1>
      </div>
      <div class="team-half2">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <img
              src="../assets/team/david.png"
              alt="David W3st"
              draggable="false"
            />
            <div class="team-name">
              <h3>David W3st</h3>
              <span>
                <a href="https://twitter.com/DavidBelsize" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.748 2.57799C17.092 2.86599 16.398 3.05799 15.688 3.14399C16.438 2.69599 16.998 1.98799 17.264 1.15599C16.56 1.57399 15.79 1.86999 14.986 2.02599C13.628 0.58199 11.358 0.50999 9.912 1.86799C9.192 2.54599 8.782 3.48999 8.78 4.47999C8.78 4.75599 8.812 5.02999 8.872 5.29999C5.99 5.15999 3.302 3.80399 1.474 1.57199C0.528 3.20599 1.01 5.29399 2.576 6.34799C2.006 6.32999 1.448 6.17599 0.948 5.89999V5.94799C0.95 7.65599 2.154 9.12599 3.828 9.46399C3.3 9.60599 2.746 9.62799 2.208 9.52599C2.676 10.988 4.024 11.99 5.558 12.016C4.286 13.01 2.718 13.548 1.106 13.544C0.82 13.544 0.534 13.526 0.25 13.492C1.892 14.55 3.806 15.11 5.76 15.104C12.372 15.104 15.97 9.63799 15.97 4.89799C15.97 4.73999 15.97 4.58599 15.97 4.43199C16.668 3.92399 17.27 3.29599 17.748 2.57799Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>Founder</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <img
              src="../assets/team/monica.webp"
              alt="Mónica"
              draggable="false"
            />
            <div class="team-name">
              <h3>Mónica</h3>
              <span>
                <a href="https://www.instagram.com/muamonique/" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M50 34.5C41.4 34.5 34.5 41.5 34.5 50C34.5 58.6 41.5 65.5 50 65.5C58.6 65.5 65.5 58.5 65.5 50C65.5 41.4 58.6 34.5 50 34.5Z"
                      fill="white"
                    />
                    <path
                      d="M96.3 30.8C96.1 25.8 95.3 22.5 94.1 19.5C92.9 16.4 91.1 13.6 88.7 11.2C86.4 8.79999 83.6 6.99999 80.4 5.79999C77.4 4.59999 74 3.89999 69.1 3.59999C64.1 3.39999 62.5 3.29999 49.9 3.29999C37.2 3.29999 35.7 3.39999 30.7 3.59999C25.7 3.79999 22.4 4.59999 19.4 5.79999C16.3 6.99999 13.5 8.79999 11.1 11.2C8.7 13.5 6.9 16.3 5.7 19.5C4.5 22.5 3.8 25.9 3.5 30.8C3.3 35.8 3.2 37.4 3.2 50C3.2 62.7 3.3 64.2 3.5 69.2C3.7 74.2 4.5 77.5 5.7 80.5C6.9 83.6 8.7 86.4 11.1 88.8C13.4 91.2 16.2 93 19.4 94.2C22.4 95.4 25.8 96.1 30.7 96.4C35.7 96.6 37.3 96.7 49.9 96.7C62.6 96.7 64.1 96.6 69.1 96.4C74.1 96.2 77.4 95.4 80.4 94.2C86.7 91.8 91.6 86.8 94 80.6C95.2 77.6 95.9 74.2 96.2 69.3C96.4 64.3 96.5 62.7 96.5 50.1C96.6 37.3 96.5 35.8 96.3 30.8ZM50 73.9C36.8 73.9 26.1 63.2 26.1 50C26.1 36.8 36.8 26.1 50 26.1C63.2 26.1 73.9 36.8 73.9 50C73.9 63.2 63.2 73.9 50 73.9ZM74.9 30.7C71.8 30.7 69.3 28.2 69.3 25.1C69.3 22 71.8 19.5 74.9 19.5C78 19.5 80.5 22 80.5 25.1C80.5 28.2 78 30.7 74.9 30.7Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>Founder</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <img
              src="../assets/team/fastlovestudios.gif"
              alt="FastloveStudio"
              draggable="false"
            />
            <div class="team-name">
              <h3>Fastlove Studios Team</h3>
              <span>
                <a href="https://www.w3st.xyz/#Section-team" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.9999 0.952148C6.8039 0.952148 0.951904 6.80415 0.951904 14.0001C0.951904 21.1961 6.8039 27.0481 13.9999 27.0481C21.1959 27.0481 27.0479 21.1961 27.0479 14.0001C27.0479 6.80415 21.1959 0.952148 13.9999 0.952148ZM12.3479 20.9721C10.5279 22.4841 7.8399 22.2601 6.3279 20.4401C4.8159 18.6201 5.0399 15.9041 6.8599 14.3921L11.1439 10.8081C12.9639 9.29615 15.6519 9.52015 17.1639 11.3401C17.5559 11.8161 17.8359 12.3761 18.0039 12.9361L16.4639 14.2241C16.4919 13.6081 16.2959 12.9641 15.8759 12.4321C14.9519 11.3401 13.3279 11.2001 12.2079 12.0961L7.9239 15.6801C6.8319 16.6041 6.6919 18.2281 7.5879 19.3481C8.5119 20.4401 10.1359 20.5801 11.2559 19.6841L12.3199 18.7881C13.0479 19.0401 13.8599 19.1521 14.6159 19.0401L12.3479 20.9721ZM21.1399 13.6081L16.8559 17.1641C15.0359 18.6761 12.3479 18.4521 10.8359 16.6321C10.4439 16.1561 10.1639 15.5961 9.9959 15.0361L11.5359 13.7481C11.5079 14.3641 11.7039 15.0081 12.1239 15.5401C13.0479 16.6321 14.6719 16.7721 15.7919 15.8761L20.0759 12.3201C21.1679 11.3961 21.3079 9.77215 20.4119 8.65215C19.4879 7.56015 17.8639 7.42015 16.7439 8.31615L15.6519 9.24015C14.9239 8.98815 14.1119 8.87615 13.3559 8.98815L15.6519 7.05615C17.4719 5.54415 20.1599 5.76815 21.6719 7.58815C23.1839 9.40815 22.9599 12.0961 21.1399 13.6081Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>Misphist Ideologist</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <img src="../assets/team/flan1.webp" alt="Flan" draggable="false" />
            <div class="team-name">
              <h3>Flan</h3>
              <span>
                <a href="https://twitter.com/flanlog" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.748 2.57799C17.092 2.86599 16.398 3.05799 15.688 3.14399C16.438 2.69599 16.998 1.98799 17.264 1.15599C16.56 1.57399 15.79 1.86999 14.986 2.02599C13.628 0.58199 11.358 0.50999 9.912 1.86799C9.192 2.54599 8.782 3.48999 8.78 4.47999C8.78 4.75599 8.812 5.02999 8.872 5.29999C5.99 5.15999 3.302 3.80399 1.474 1.57199C0.528 3.20599 1.01 5.29399 2.576 6.34799C2.006 6.32999 1.448 6.17599 0.948 5.89999V5.94799C0.95 7.65599 2.154 9.12599 3.828 9.46399C3.3 9.60599 2.746 9.62799 2.208 9.52599C2.676 10.988 4.024 11.99 5.558 12.016C4.286 13.01 2.718 13.548 1.106 13.544C0.82 13.544 0.534 13.526 0.25 13.492C1.892 14.55 3.806 15.11 5.76 15.104C12.372 15.104 15.97 9.63799 15.97 4.89799C15.97 4.73999 15.97 4.58599 15.97 4.43199C16.668 3.92399 17.27 3.29599 17.748 2.57799Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>
              Art Direction & <br />
              Creative Coding Expert
            </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <img src="../assets/team/iago.webp" alt="Iago" draggable="false" />
            <div class="team-name">
              <h3>Iago</h3>
              <span>
                <a href="http://www.iiago.com/" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.748 2.57799C17.092 2.86599 16.398 3.05799 15.688 3.14399C16.438 2.69599 16.998 1.98799 17.264 1.15599C16.56 1.57399 15.79 1.86999 14.986 2.02599C13.628 0.58199 11.358 0.50999 9.912 1.86799C9.192 2.54599 8.782 3.48999 8.78 4.47999C8.78 4.75599 8.812 5.02999 8.872 5.29999C5.99 5.15999 3.302 3.80399 1.474 1.57199C0.528 3.20599 1.01 5.29399 2.576 6.34799C2.006 6.32999 1.448 6.17599 0.948 5.89999V5.94799C0.95 7.65599 2.154 9.12599 3.828 9.46399C3.3 9.60599 2.746 9.62799 2.208 9.52599C2.676 10.988 4.024 11.99 5.558 12.016C4.286 13.01 2.718 13.548 1.106 13.544C0.82 13.544 0.534 13.526 0.25 13.492C1.892 14.55 3.806 15.11 5.76 15.104C12.372 15.104 15.97 9.63799 15.97 4.89799C15.97 4.73999 15.97 4.58599 15.97 4.43199C16.668 3.92399 17.27 3.29599 17.748 2.57799Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>
              Art Direction &<br />
              Art & Concept
            </p>
          </div>

          <div class="col-md-6 col-lg-3">
            <img
              src="../assets/team/esteve.png"
              alt="Esteve Serra"
              draggable="false"
            />
            <div class="team-name">
              <h3>Esteve Serra</h3>
              <span>
                <a href="https://twitter.com/nosoccomtothom" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.748 2.57799C17.092 2.86599 16.398 3.05799 15.688 3.14399C16.438 2.69599 16.998 1.98799 17.264 1.15599C16.56 1.57399 15.79 1.86999 14.986 2.02599C13.628 0.58199 11.358 0.50999 9.912 1.86799C9.192 2.54599 8.782 3.48999 8.78 4.47999C8.78 4.75599 8.812 5.02999 8.872 5.29999C5.99 5.15999 3.302 3.80399 1.474 1.57199C0.528 3.20599 1.01 5.29399 2.576 6.34799C2.006 6.32999 1.448 6.17599 0.948 5.89999V5.94799C0.95 7.65599 2.154 9.12599 3.828 9.46399C3.3 9.60599 2.746 9.62799 2.208 9.52599C2.676 10.988 4.024 11.99 5.558 12.016C4.286 13.01 2.718 13.548 1.106 13.544C0.82 13.544 0.534 13.526 0.25 13.492C1.892 14.55 3.806 15.11 5.76 15.104C12.372 15.104 15.97 9.63799 15.97 4.89799C15.97 4.73999 15.97 4.58599 15.97 4.43199C16.668 3.92399 17.27 3.29599 17.748 2.57799Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>Crypto Mentor</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <img
              src="../assets/team/xabier.webp"
              alt="Xabier"
              draggable="false"
            />
            <div class="team-name">
              <h3>Xabier Iglesias</h3>
              <span>
                <a href="https://twitter.com/xabier_iglesias" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.748 2.57799C17.092 2.86599 16.398 3.05799 15.688 3.14399C16.438 2.69599 16.998 1.98799 17.264 1.15599C16.56 1.57399 15.79 1.86999 14.986 2.02599C13.628 0.58199 11.358 0.50999 9.912 1.86799C9.192 2.54599 8.782 3.48999 8.78 4.47999C8.78 4.75599 8.812 5.02999 8.872 5.29999C5.99 5.15999 3.302 3.80399 1.474 1.57199C0.528 3.20599 1.01 5.29399 2.576 6.34799C2.006 6.32999 1.448 6.17599 0.948 5.89999V5.94799C0.95 7.65599 2.154 9.12599 3.828 9.46399C3.3 9.60599 2.746 9.62799 2.208 9.52599C2.676 10.988 4.024 11.99 5.558 12.016C4.286 13.01 2.718 13.548 1.106 13.544C0.82 13.544 0.534 13.526 0.25 13.492C1.892 14.55 3.806 15.11 5.76 15.104C12.372 15.104 15.97 9.63799 15.97 4.89799C15.97 4.73999 15.97 4.58599 15.97 4.43199C16.668 3.92399 17.27 3.29599 17.748 2.57799Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>Discord Lord</p>
          </div>
          <div class="col-md-6 col-lg-3">
            <img src="../assets/team/yomi.webp" alt="Yomi" draggable="false" />
            <div class="team-name">
              <h3>Yomi</h3>
              <span>
                <a href="https://yomi.digital/" target="_blank">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.9999 0.952148C6.8039 0.952148 0.951904 6.80415 0.951904 14.0001C0.951904 21.1961 6.8039 27.0481 13.9999 27.0481C21.1959 27.0481 27.0479 21.1961 27.0479 14.0001C27.0479 6.80415 21.1959 0.952148 13.9999 0.952148ZM12.3479 20.9721C10.5279 22.4841 7.8399 22.2601 6.3279 20.4401C4.8159 18.6201 5.0399 15.9041 6.8599 14.3921L11.1439 10.8081C12.9639 9.29615 15.6519 9.52015 17.1639 11.3401C17.5559 11.8161 17.8359 12.3761 18.0039 12.9361L16.4639 14.2241C16.4919 13.6081 16.2959 12.9641 15.8759 12.4321C14.9519 11.3401 13.3279 11.2001 12.2079 12.0961L7.9239 15.6801C6.8319 16.6041 6.6919 18.2281 7.5879 19.3481C8.5119 20.4401 10.1359 20.5801 11.2559 19.6841L12.3199 18.7881C13.0479 19.0401 13.8599 19.1521 14.6159 19.0401L12.3479 20.9721ZM21.1399 13.6081L16.8559 17.1641C15.0359 18.6761 12.3479 18.4521 10.8359 16.6321C10.4439 16.1561 10.1639 15.5961 9.9959 15.0361L11.5359 13.7481C11.5079 14.3641 11.7039 15.0081 12.1239 15.5401C13.0479 16.6321 14.6719 16.7721 15.7919 15.8761L20.0759 12.3201C21.1679 11.3961 21.3079 9.77215 20.4119 8.65215C19.4879 7.56015 17.8639 7.42015 16.7439 8.31615L15.6519 9.24015C14.9239 8.98815 14.1119 8.87615 13.3559 8.98815L15.6519 7.05615C17.4719 5.54415 20.1599 5.76815 21.6719 7.58815C23.1839 9.40815 22.9599 12.0961 21.1399 13.6081Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </span>
            </div>

            <p>Web3 Expeditionaries</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style>
</style>