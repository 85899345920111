<template>
  <div class="editing_modal_wrapper">
    <div class="editing_modal">
      <img src="../assets/icons/close.svg" style="width:25px" alt="Cancel" @click="$emit('cancel')">
      <h4 class="mt-2 pt-2" v-html="title"></h4>
      <div class="d-flex mx-auto mt-4">
        <button class="cancelBtn" @click="$emit('cancel')">Cancel</button>
        <button class="confirmBtn" @click="$emit('confirm')">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditingModal',
  props: ['title']
}
</script>
